import { render, staticRenderFns } from "./ServicePortfolioSection.vue?vue&type=template&id=c9f3f682&scoped=true"
import script from "./ServicePortfolioSection.vue?vue&type=script&lang=js"
export * from "./ServicePortfolioSection.vue?vue&type=script&lang=js"
import style0 from "./ServicePortfolioSection.vue?vue&type=style&index=0&id=c9f3f682&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9f3f682",
  null
  
)

export default component.exports